import placeholderImage from 'assets/images/placeholder.png'
import ReadMoreButton from 'components/ReadMoreButton'
import Image, { StaticImageData } from 'components/Image'
import classNames from 'classnames'
import CarouselHeaders from 'components/home/common/typography/CarouselHeaders'
import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactChild } from 'data/types/types'
import Link from 'components/Link'

type PropTypes = {
  subtitle?: string
  title?: string
  text?: string | string[] | JSX.Element
  buttonText?: string
  buttonHref?: string
  buttonAction?: () => void
  image?: {
    url: string | StaticImageData
    altText: string
  }
  footer?: ReactChild
  className?: {
    container?: string
    content?: {
      container?: string
      title?: string
      text?: string
      button?: string
    }
    image?: {
      container?: string
      image?: string
    }
  }
  inlinelink?: {
    label: string,
    href: string
  }
  variant?: 'default' | 'full-width'
}

const InformationSection = ({
  subtitle,
  title,
  text,
  buttonText,
  buttonHref,
  buttonAction,
  image,
  className,
  footer,
  inlinelink,
  variant = 'default',
}: PropTypes) => {
  return (
    <div className={classNames(
      'flex md:flex-row gap-y-50 gap-x-20 lg:gap-x-75 xl:gap-x-90 justify-between w-full lg:mx-auto lg:mt-150 mb-75 xl:mb-150 -mt-20 md:mt-10 pt-20 md:pt-0 z-[-1]',
      {
        'lg:max-w-[1050px] flex-col items-center': variant === 'default',
        'lg:max-w-[1280px] flex-col-reverse lg:items-center': variant === 'full-width',
      },
      className?.container,
    )}>
      <div className={classNames(
        'flex flex-col justify-center h-full gap-20 px-20 pt-10 mx-auto my-auto md:w-full',
        className?.content?.container,
      )}>
        {
          subtitle && (
            <CarouselHeaders.H5 Tag="p" className="ml-5 text-grey-650 !leading-20 uppercase">
              {subtitle}
            </CarouselHeaders.H5>
          )
        }

        {
          title && (
            <Headers.Title Tag="h2" className={classNames(
              'text-grey-900 md:max-w-[486px]',
              className?.content?.title,
            )}>
              {title}
            </Headers.Title>
          )
        }

        {
          text && (
            <Paragraphs.MD Tag="div" className={classNames(
              'text-grey-750 !leading-22 md:max-w-[507px] w-full',
              className?.content?.text,
            )}>
              <div className="flex flex-col gap-20">
                {
                  Array.isArray(text)
                    ? text.map((t,index) => <p className="w-full" key={index}>{t}</p>)
                    : text
                }
              </div>
              { inlinelink && (
                <Link href={inlinelink.href} className="underline text-grey-750">{inlinelink.label}</Link>
              )}
            </Paragraphs.MD>
          )
        }

        {
          buttonText && (buttonAction || buttonHref) && (
            <ReadMoreButton
              {...(
                (buttonHref
                  ? { href: buttonHref }
                  : { onClick: buttonAction }
                ) as ({ href: string } | { onClick: () => void })
              )}
              className="!mt-10 w-max"
            >
              {buttonText}
            </ReadMoreButton>
          )
        }

        {footer}
      </div>

      <div className={classNames(
        'w-full relative mx-auto',
        {
          'md:max-w-[450px] h-[400px] md:h-[600px]': variant === 'default',
          'h-[450px] md:h-[660px] mr-20': variant === 'full-width',
        },
        className?.image?.container,
      )}>
        <Image
          src={image?.url ?? placeholderImage}
          alt={image?.altText}
          layout="fill"
          className={classNames(
            'object-cover',
            {
              'rounded-4': variant === 'default',
              'md:rounded-4': variant === 'full-width',
            },
          )}
        />
      </div>
    </div>
  )
}

export async function getServerSideProps () {
}

export default InformationSection
