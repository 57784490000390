import { useEffect } from 'react'
import ServiceSlide, { ServiceSlideItemProps } from './ServiceSlide'
import { ArrowRightIcon } from 'components/icons'
import { Swiper, SwiperSlide } from 'swiper/react'
import Link from 'components/Link'
import Button from 'components/home/common/Button'
import { useCarouselLoadedImages } from 'data/hooks/useCarouselLoadedImages'
import classNames from 'classnames'
import { useHasLoaded } from 'data/hooks/useHasLoaded'
import CarouselTextContent, { CarouselTextContentProps } from './carousel/CarouselTextContent'

type SliderItemOptionalProps = {
  href: string
  onClick?: never
} | {
  href?: never
  onClick: () => void
}

export interface ServiceSliderProps extends Omit<CarouselTextContentProps, 'className'> {
  id?: string
  onClick?: (item: ServiceSlideItemProps) => void
  className?: {
    container?: string,
    superHeading?: string,
  } & CarouselTextContentProps['className']
}

interface ServiceSliderPropsWithItems extends ServiceSliderProps {
  items: (ServiceSlideItemProps & SliderItemOptionalProps)[]
}

const ServiceSlider = ({
  heading,
  subheading,
  description,
  items,
  isFetching = false,
  button,
  className,
  variant = 'default',
}: ServiceSliderPropsWithItems) => {
  const imagesEitherSide = 6
  const { onRealIndexChange, isImageLoaded } = useCarouselLoadedImages({
    imagesEitherSide,
    imagesLength: items.length,
  })

  const breakpoints = {
    default: {
      1024: {
        spaceBetween: 30,
      },
    },
    mobile: {},
    centered: {
      640: {
        spaceBetween: 30,
      },
      1024: {
        spaceBetween: 42,
      },
    },
  }

  useEffect(() => {
    if (!isFetching && items.length > 0) {
      onRealIndexChange(0)
    }
  }, [isFetching])

  const { ref: componentRef, hasLoaded } = useHasLoaded()

  return (
    <div className={classNames(
      'w-full antialiased flex flex-col',
      className?.container,
      {
        'md:flex-row': variant === 'default',
      },
    )}>
      <CarouselTextContent
        heading={heading}
        subheading={subheading}
        description={description}
        isFetching={isFetching}
        button={button}
        className={className}
        variant={variant}
      />

      <div
        ref={componentRef}
        className={classNames({
          'md:w-1/2 lg:pt-40': heading && description,
        })}
      >
        {
          hasLoaded && (
            <Swiper
              className="relative h-full"
              slidesPerView={'auto'}
              spaceBetween={10}
              breakpoints={breakpoints[variant]}
              slidesOffsetBefore={24}
              slidesOffsetAfter={24}
              onRealIndexChange={(swiper) => onRealIndexChange(swiper.realIndex)}
            >
              {!isFetching && items && items.map((item, index: number) => (
                <SwiperSlide
                  key={index}
                  className={classNames(
                    'w-[172px]',
                    {
                      'lg:w-[275px]': variant === 'default',
                      'w-[275px]': variant === 'centered',
                    },
                  )}
                >
                  {item.href
                    ? (
                      <Link href={item.href}>
                        <ServiceSlide
                          {...item}
                          isImageLoaded={isImageLoaded(index)}
                          variant={variant}
                        />
                      </Link>
                    )
                    : (
                      <button type="button" onClick={item.onClick} className="w-full">
                        <ServiceSlide
                          {...item}
                          isImageLoaded={isImageLoaded(index)}
                          variant={variant}
                        />
                      </button>
                    )
                  }
                </SwiperSlide>
              ))}

              {!isFetching && items && button && (
                <SwiperSlide className={classNames(
                  'w-[172px] flex flex-col justify-center h-auto bg-grey-250 bg-opacity-30 rounded-4 px-10',
                  {
                    'lg:w-[275px]': variant === 'default',
                  },
                )}>
                  <Button
                    href={button.href}
                    variant="secondary"
                    className={{
                      button: classNames(
                        'flex gap-10 items-center mx-auto text-center !text-12',
                        {
                          'lg:!text-14': variant === 'default',
                        },
                      ),
                    }}
                  >
                    <ArrowRightIcon className="hidden lg:block" /> {button?.text}
                  </Button>
                </SwiperSlide>
              )}
            </Swiper>
          )
        }
      </div>
    </div>
  )
}

export default ServiceSlider
