import Image, { StaticImageData } from 'components/Image'
import { RequireOnlyOne } from 'data/helpers/types/requireOnlyOne'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import SectionHeaders from 'components/home/common/typography/SectionHeaders'
import Button, { ButtonProps } from 'components/home/common/Button'
import { InfoCircleThinIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'

type PropTypes = {
  includePadding?: boolean
  bannerImage: string | StaticImageData
  altText?: string
  subtitle?: string
  heading?: string
  content?: string
  buttonText?: ButtonProps['children']
  buttonAction?: ButtonProps['onClick']
  buttonHref?: ButtonProps['href']
  buttonIcon?: ReactChild
  buttonIsLoading?: boolean
  smShowContent?: boolean
  children?: ReactChild
  className?: {
    container?: string
    image?: string
    content?: string
  }
}

const BannerCardSection = ({
  includePadding = true,
  bannerImage,
  altText,
  subtitle,
  heading,
  content,
  children,
  buttonText,
  buttonAction,
  buttonHref,
  buttonIsLoading,
  buttonIcon = <InfoCircleThinIcon />,
  smShowContent = false,
  className,
}: RequireOnlyOne<PropTypes, 'buttonAction' | 'buttonHref'>) => {
  return (
    <div
      className={classNames('relative flex flex-col items-center justify-center', {
        'my-75 lg:my-[125px]': includePadding,
      },
      className?.container,
      )}
    >
      <div className={classNames(
        'relative h-[399px] lg:h-[900px] w-full',
        className?.image,
      )}>
        <Image
          src={bannerImage}
          alt={altText}
          className="object-cover"
          layout="fill"
        />
      </div>
      <div className="relative w-full lg:absolute -mt-80 sm:-mt-100 lg:mt-0 container-public !px-25">
        <div className="sm:max-w-[686px] w-full bg-white shadow-availability-form rounded-6 p-25 sm:px-60 sm:py-75 mx-auto lg:mx-0">
          <div className="max-w-[525px] flex flex-col gap-15 sm:gap-20 items-center sm:items-start">
            <SectionHeaders.Caption className="!font-poppins text-grey-650">
              {subtitle}
            </SectionHeaders.Caption>

            <SectionHeaders.XXL Tag="h2" className="text-center sm:text-left normal-case !font-bold">
              {heading}
            </SectionHeaders.XXL>

            <Paragraphs.PropertyPage className={classNames(
              'hidden antialiased text-grey-750 sm:block',
              className?.content,
            )}>
              {content}
            </Paragraphs.PropertyPage>

            {children}

            {buttonText && (buttonAction || buttonHref)
              && <div className={classNames('my-10 w-max', {
                'mt-30': smShowContent,
                'sm:mt-30': !smShowContent,
              })}>
                <Button
                  variant="secondary"
                  {...(buttonAction ? { onClick: buttonAction } : { href: buttonHref })}
                  icon={buttonIcon}
                  isLoading={buttonIsLoading}
                >
                  {buttonText}
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerCardSection
