import React from 'react'
import { QuoteLeftIcon, QuoteRightIcon } from 'components/icons'
import Link from 'next/link'
import PropTypes from 'prop-types'

const GuestReviewItem = ({ children, reviewer, villa }) => {
  return (
    <div className="flex gap-5 px-20 md:gap-25 mt-25 lg:mt-100">
      <div>
        <QuoteRightIcon className="scale-[0.58] lg:scale-[0.725]" />
      </div>
      <div className="w-full mt-40">
        <div className="font-serif text-20 leading-26 text-grey-750">
          {children}
        </div>
        <div className="text-grey-500 mt-[46px]">
          <p>{reviewer}</p>
          <p>
            Stayed at {villa.url ? <Link href={villa.url}><a className="underline">{villa.title}</a></Link> : villa.title}
          </p>
        </div>
      </div>
      <div className="self-end mb-80 lg:mb-40">
        <QuoteLeftIcon className="scale-[0.7] lg:scale-[0.875]" />
      </div>
    </div>

  )
}

GuestReviewItem.propTypes = {
  children: PropTypes.node.isRequired,
  reviewer: PropTypes.string.isRequired,
  villa: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
}

export default GuestReviewItem
