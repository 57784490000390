import { useFormContext } from 'react-hook-form'
import { DateRangeInput } from 'components/dates/DateRangePicker'
import { PropertyFilterFields } from 'components/modals/home/search/properties/PropertyFiltersModal'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import NavbarPopover from '../popovers/components/NavbarPopover'
import NavbarButton from '../common/NavbarButton'
import { FilterSearchBarIcon, SearchAltIcon } from 'components/icons'
import Button from 'components/home/common/Button'
import { getSearchURLQueryParameters, pluraliseWithNumber } from '@ama-selections/ui'
import ControlledPropertyFilters from 'components/modals/home/search/properties/ControlledPropertyFilters'
import { compact } from 'lodash'
import { useMemo } from 'react'
import DestinationSelectionPopover from 'components/home/navbar/popovers/specific/DestinationSelectionPopover'
import DateRangeSelectionPopover from '../popovers/specific/DateRangeSelectionPopover'
import { getDestinationSearchPathname } from 'data/helpers/destination'
import { useRouter } from 'next/router'
import { useDestinationFetching } from 'data/hooks/Destinations/useDestinationFetching'
import GuestsSelectionPopover from '../popovers/specific/GuestsSelectionPopover'

export type DestinationSearchFormFields = {
  adults: number | undefined
  children: number | undefined
  dates: DateRangeInput | undefined
  filters: PropertyFilterFields | undefined
  destination: SelectedDestinationData | undefined
  destination_search: string | undefined
}

const FloatingDestinationNavbar = () => {
  const router = useRouter()
  const { watch } = useFormContext<DestinationSearchFormFields>()

  const { isDestinationFetching } = useDestinationFetching()

  const numberOfAppliedFilters = useMemo(() => {
    const filters = watch('filters')

    if (!filters) {
      return 0
    }

    let selectedLength = compact(Object.values(filters)).length

    if (filters.searchCategories) {
      selectedLength += filters.searchCategories.length - 1
    }

    if (filters.propertyTypes) {
      selectedLength += filters.propertyTypes.length - 1
    }

    return selectedLength
  }, [watch(['filters.bathrooms', 'filters.bedrooms', 'filters.maximumPrice', 'filters.minimumPrice', 'filters.propertyTypes', 'filters.searchCategories'])])

  return (
    <div className="relative flex py-20 bg-white rounded-full px-50 max-w-[954px]">
      <form className="flex flex-grow divide-x divide-grey-250">
        <DestinationSelectionPopover
          className={{
            button: 'ml-0',
            container: '!top-0',
            panel: '!top-[calc(100%+2px)] !ml-20',
          }}
        />

        <DateRangeSelectionPopover
          className={{
            container: '!top-0',
            panel: '!top-[calc(100%+2px)]',
          }}
        />

        <GuestsSelectionPopover
          className={{
            container: '!top-0 !min-w-[206px]',
            panel: '!top-[calc(100%+22px)]',
          }}
        />

        <NavbarPopover
          trigger={
            <NavbarButton
              prefix={<FilterSearchBarIcon />}
              active={numberOfAppliedFilters > 0}
              className={{ container: '!min-w-[170px]' }}
            >
              {pluraliseWithNumber(
                numberOfAppliedFilters,
                'Filter',
                { showPlaceholderOnNull: true },
              )}
            </NavbarButton>
          }
          variant="fixed"
          className={{
            panel: '!pb-0 !pt-40 !top-[calc(100%+2px)]',
          }}
        >
          {({ close }) => (
            <ControlledPropertyFilters
              onFilter={close}
              button={{
                style: 'pill',
              }}
            />
          )}
        </NavbarPopover>
      </form>

      <div className="flex items-center">
        <Button
          style="pill"
          className={{
            button: '!py-[7px] !px-[21px]',
          }}
          href={{
            pathname: getDestinationSearchPathname(watch('destination')),
            query: getSearchURLQueryParameters({
              sort: router?.query?.sort,
              adults: watch('adults'),
              children: watch('children'),
              arrival: watch('dates')?.startDate,
              departure: watch('dates')?.endDate,
              bedrooms: watch('filters')?.bedrooms,
              bathrooms: watch('filters')?.bathrooms,
              minimumPrice: watch('filters')?.minimumPrice,
              maximumPrice: watch('filters')?.maximumPrice,
              propertyTypes: watch('filters')?.propertyTypes,
              searchCategories: watch('filters')?.searchCategories,
            }),
          }}
          isLoading={isDestinationFetching}
        >
          <SearchAltIcon className="text-18" />
        </Button>
      </div>
    </div>
  )
}

export default FloatingDestinationNavbar
